import {Injectable} from "@angular/core";
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from "../../../@fuse/components/navigation";
import {BehaviorSubject, Observable, take} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {NavigationContentIDs, Navigations} from "../enums/navigations.enum";
import {selectSmartVuItems} from "../../store/smartvus";
import {Store} from "@ngrx/store";
import {SharedService} from "./shared.service";
import feature_flags from "../../../environments/feature-flags.json";
import {File} from "../models/file";
import {FileManagerService} from "../../modules/file-manager/file-manager.service";
import {SharingAndEmbeddingService} from "../../modules/sharing-and-embedding/sharing-embedding.service";
import {FilterService} from "./filter.service";
import {Share} from "../models/share";
import {Embed} from "../models/embed";

/**
 * This service handles the logic for the fuse navigations which we use in the app.
 */
@Injectable({providedIn: 'root'})
export class NavigationBuilderService {
    private _closedSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    closedSidebar$: Observable<boolean> = this._closedSidebar.asObservable();

    private _closedSubMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    closedSubMenu$: Observable<boolean> = this._closedSubMenu.asObservable();

    private _activeNavigationItem: BehaviorSubject<any> = new BehaviorSubject<any>({});
    activeNavigationItem$: Observable<any> = this._activeNavigationItem.asObservable();

    private _activeNavigationContentId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    activeNavigationContentId$: Observable<string> = this._activeNavigationContentId.asObservable();

    private smartVus$ = this._store.select(selectSmartVuItems);


    constructor(private _fuseNavigationService: FuseNavigationService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _translocoService: TranslocoService,
                private _store: Store,
                private _sharedService: SharedService,
                private _fileManagerService: FileManagerService,
                private _shareAndEmbedService: SharingAndEmbeddingService,
                private _filterService: FilterService) {
    }


    //
    // Public methods
    //

    /**
     * This method builds a fuse navigation. It filters the navigation items by checking the feature flags and translates the navigation items titles.
     * @param lang - user language
     * @param navigationContentId - the content id of the navigation
     */
    buildNavigation(lang: string, navigationContentId: string): FuseNavigationItem[] {
        // get the translated navigation
        const navigationData = this.getNavigationData(this._translocoService.getTranslation(lang), navigationContentId);

        const featureFlags = feature_flags;

        // build the navigation
        const navigationWithFilteredChildren = navigationData.map((navigationItem: FuseNavigationItem) => {

            if (navigationItem) {
                return {
                    ...navigationItem, children: navigationItem?.children?.filter((submenuItem) => {
                        const featureOptions = featureFlags.find(el => el.identifier === submenuItem?.id);

                        return featureOptions?.enabled;
                    })
                }
            }


        })

        return navigationWithFilteredChildren.filter((navigationItem: FuseNavigationItem) => navigationItem.children?.length !== 0);
    }


    /**
     * Swap navigation data
     *
     * @param navigationName - The name of the navigation which will be changed.
     * @param navigationContentId - The id of the content which should be rendered.
     */
    swapNavigationData(navigationName: string, navigationContentId: NavigationContentIDs, activeItemId?: string): void {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(navigationName);

        // A navigation data to replace with
        const newNavigationData = this.buildNavigation(this._translocoService.getActiveLang(), navigationContentId);


        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }


        // Replace the navigation data
        navComponent.navigation = newNavigationData;

        // Update badge numbers if the navigation id is 'smartvus'
        switch (navigationContentId) {
            case NavigationContentIDs.smartvus:
                this.updateSmartVusNavBadgeTitles();
                break;
            case NavigationContentIDs.shares:
                this.updateSharedContentBadgeTitles();
                break;
            case NavigationContentIDs.embeds:
                this.updateEmbedContentBadgeTitles();
                break;
        }

        if (activeItemId) {
            const item = this._fuseNavigationService.getItem(activeItemId, newNavigationData);
            item.active = true;

            for (const element of newNavigationData) {
                if (element.children) {
                    for (const child of element.children) {
                        if (child.id !== item.id) {
                            child.active = false;
                        }
                    }
                }
            }
        }


        this._activeNavigationContentId.next(navigationContentId);
        navComponent.refresh();
    }

    /**
     * This method refreshes a navigation. We need this refresh after we've made
     * changes to some navigation elements.
     * @param navigationName
     */
    refreshNavigation(navigationName: string): void {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(navigationName);
        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        navComponent.refresh();
    }

    /**
     * Returns a category from a fuse navigation.
     * @param id
     * @param navigationName
     */
    getNavigationCategoryById(id: string, navigationName: string): FuseNavigationItem {
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(navigationName);
        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;

        return this._fuseNavigationService.getItem(id, navigation);
    }

    /**
     * Update badge title for a single navigation element.
     *
     * @param itemId
     * @param itemsCount
     * @param navigationName
     */
    updateItemBadgeTitle(itemId, itemsCount, navigationName: string): void {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(navigationName);
        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        const item = this._fuseNavigationService.getItem(itemId, navigation);

        if (item.badge) {
            item.badge.title = itemsCount;
        }
        navComponent.refresh();
    }

    /**
     * This method sets the badge titles for the SmartVus navigation.
     */
    updateSmartVusNavBadgeTitles(): void {
        this.smartVus$
            .subscribe(({smartvus}) => {
                if (smartvus && smartvus.length) {
                    const smartVusCategory = this.getNavigationCategoryById('all_smartvus', Navigations.subNavigation);
                    this.updateItemBadgeTitle(smartVusCategory.children[0].id, smartvus.length, Navigations.subNavigation)
                }
            })
    }

    /**
     * This method sets the badge titles for the Uploads navigation.
     */
    updateUploadsBadgeTitles(files: File[]): void {
        this._fileManagerService.files$.subscribe(files => {
            if (files && files.length) {
                //get processing nav item
                const processingNavItem: FuseNavigationItem = this.getNavigationCategoryById('processing', Navigations.subNavigation);

                if (processingNavItem) {
                    const processingItems = files.filter(file => file.status !== 'failed' && file.trashedAt === null);
                    this.updateItemBadgeTitle(processingNavItem.id, processingItems.length, Navigations.subNavigation);
                }


                //get failed nav item
                const failedNavItem: FuseNavigationItem = this.getNavigationCategoryById('failed', Navigations.subNavigation);

                if (failedNavItem) {
                    const failedItems = files.filter(file => file.status === 'failed' && file.trashedAt === null);
                    this.updateItemBadgeTitle(failedNavItem.id, failedItems.length, Navigations.subNavigation);
                }

            }
        })


    }


    /**
     * This method sets the badge titles for the Shared Content navigation.
     */
    updateSharedContentBadgeTitles() {
        this._shareAndEmbedService.allProducts$.subscribe((allProducts: Share[]) => {

                if (allProducts) {
                    //get processing nav item
                    const allSharesNavItem: FuseNavigationItem = this.getNavigationCategoryById('shares', Navigations.subNavigation);

                    if (allSharesNavItem) {
                        this.updateItemBadgeTitle(allSharesNavItem.id, allProducts.length, Navigations.subNavigation);
                    }


                    //get deactivated nav item
                    const deactivatedNavItem: FuseNavigationItem = this.getNavigationCategoryById('shares_deactivated', Navigations.subNavigation);

                    if (deactivatedNavItem) {
                        const disabledItems = allProducts.filter(product => product.deleted);
                        this.updateItemBadgeTitle(deactivatedNavItem.id, disabledItems.length, Navigations.subNavigation);
                    }


                    //get password protected nav item
                    const passwordProtectedNavItem: FuseNavigationItem = this.getNavigationCategoryById('shares_password_protected', Navigations.subNavigation);

                    if (passwordProtectedNavItem) {
                        const disabledItems = allProducts.filter(product => product.password_protected);
                        this.updateItemBadgeTitle(passwordProtectedNavItem.id, disabledItems.length, Navigations.subNavigation);
                    }


                }
            }
        )

    }


    /**
     * This method sets the badge titles for the Shared Content navigation.
     */
    updateEmbedContentBadgeTitles() {
        this._shareAndEmbedService.allProducts$.subscribe((allProducts: Embed[]) => {
                if (allProducts) {
                    //get processing nav item
                    const allEmbedsNavItem: FuseNavigationItem = this.getNavigationCategoryById('embeds', Navigations.subNavigation);

                    if (allEmbedsNavItem) {
                        this.updateItemBadgeTitle(allEmbedsNavItem.id, allProducts.length, Navigations.subNavigation);
                    }

                    //get deactivated nav item
                    const deactivatedNavItem: FuseNavigationItem = this.getNavigationCategoryById('embeds_deactivated', Navigations.subNavigation);

                    if (deactivatedNavItem) {
                        const disabledItems = allProducts.filter((product: Embed) => product.disabled);
                        this.updateItemBadgeTitle(deactivatedNavItem.id, disabledItems.length, Navigations.subNavigation);
                    }
                }
            }
        )

    }


    //
    // Private methods
    //


    /**
     * This method returns the translated navigation data. The navigation data for each navigation which we use is stored as array of objects in the method.
     * @param translations - the object containing translations
     * @param navigationContentId - the content id of the navigation
     * @private
     */
    private getNavigationData(translations: any, navigationContentId: string): FuseNavigationItem[] {

        // we will use the path parameters in order to set the initial active state on the sidebars
        const pathParameters = window.location.pathname;

        // First navigation, called also main navigation. When an element from this navigation is clicked we can trigger a "content swap" in the sub-navigation.
        // The "content swap" is a change of the navigation data and is handled from the swapNavigationData() method.
        const mainNavigationData: FuseNavigationItem[] = [
            {
                id: 'visualize',
                title: translations['sidebar.visualize'],
                subtitle: translations['sidebar.smart-3d-renderings'],
                type: 'group',
                children: [
                    {
                        id: 'smartvus',
                        title: "SmartVu's",
                        active: pathParameters.includes('smartvus'),
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.navigateToRouteInWorkspace('/smartvus');
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.smartvus, 'smartvus');
                            const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(Navigations.mainNavigation);
                            navigation.close();
                        },
                    },
                    {
                        id: 'images_and_assets',
                        title: translations['sidebar.images-and-assets'],
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.dummy);
                        }
                    }
                ]
            },
            {
                id: 'present',
                title: translations['sidebar.present'],
                subtitle: translations['sidebar.smart-3d-for-marketing-and-sales'],
                type: 'group',
                children: [
                    {
                        id: 'presentations',
                        title: translations['sidebar.presentations'],
                        active: pathParameters.includes('presentations'),
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.navigateToRouteInWorkspace('/presentations');
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.smartvus);
                        },
                    },
                    {
                        id: 'showrooms',
                        title: 'Showrooms',
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.dummy);
                        }
                    },
                    {
                        id: 'catalogs',
                        title: translations['sidebar.catalogs'],
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                        }
                    },
                    {
                        id: 'kiosk_displays',
                        title: translations['sidebar.kiosk-displays'],
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                        }
                    },
                ]
            },

            {
                id: 'share_and_publish',
                title: translations['sidebar.share-and-publish'],
                subtitle: translations['sidebar.cross-platform'],
                type: 'group',
                children: [
                    {
                        id: 'team',
                        title: 'Team',
                        active: pathParameters.includes('team'),
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.dummy);
                        },
                    },
                    {
                        id: 'shared_content',
                        title: translations['sidebar.shared-content'],
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        active: pathParameters.includes('/shares'),
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.navigateToRouteInWorkspace('/shares');
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.shares, 'shares');
                            const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(Navigations.mainNavigation);
                            navigation.close();
                        }
                    },
                    {
                        id: 'web_publishing',
                        title: translations['sidebar.web-publishing'],
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        active: pathParameters.includes('/embeds'),
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.navigateToRouteInWorkspace('/embeds');
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.embeds, 'embeds');
                            const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(Navigations.mainNavigation);
                            navigation.close();
                        }
                    },
                    {
                        id: 'your_apps',
                        title: translations['sidebar.your-apps'],
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'presentation',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                            this.swapNavigationData(Navigations.subNavigation, NavigationContentIDs.dummy);
                        }
                    },
                ]
            },
            {
                id: 'divider-1',
                type: 'divider'
            },
            {
                title: translations['sidebar.ressources'],
                type: 'group',
                children: [
                    {
                        id: 'apps_and_downloads',
                        title: 'Apps & Downloads',
                        //active: pathParameters.includes('/learning-centre/success-stories'),
                        type: 'basic',
                        icon: 'heroicons_outline:document-duplicate',
                        function: (item) => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                        }
                    },
                    {
                        id: 'studio',
                        title: 'Studio',
                        type: 'basic',
                        icon: 'mat_outline:open_in_new',
                        classes: {
                            wrapper: 'studio-button',
                            icon: 'opacity-1'
                        },
                        function: (item) => {
                            window.open('https://studio.vuframe.com', '_blank');

                        }
                    },
                    {
                        id: 'examples',
                        title: translations['sidebar.examples'],
                        active: pathParameters.includes('/learning-centre/examples'),
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        link: '/learning-centre/examples',
                        function: (item) => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                        }
                    },
                    {
                        id: 'tutorials',
                        title: translations['sidebar.tutorials'],
                        active: pathParameters.includes('/learning-centre/tutorials'),
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        link: '/learning-centre/tutorials',
                        function: (item) => {
                            this.setActiveStatus(item, mainNavigationData, Navigations.mainNavigation);
                        }
                    },
                ]
            }
        ];

        // Submenu for SmartVus
        const smartVusNavigationData: FuseNavigationItem[] = [
            {
                id: 'all_smartvus',
                type: 'group',
                classes: {
                    wrapper: 'hidden'
                },
                children: [
                    {
                        id: "smartvus",
                        title: translations['sidebar.all'],
                        active: pathParameters.includes('/smartvus') && !pathParameters.includes('/upload'),
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:collection',
                        meta: {
                            category: 'catalog',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, smartVusNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/smartvus');
                        },
                    }
                ]

            },
            {

                id: 'uploads',
                title: 'Uploads',
                type: 'group',
                children: [
                    {
                        id: 'new_upload',
                        title: translations['upload'],
                        active: pathParameters.includes('/upload') && !pathParameters.includes('/failed') && !pathParameters.includes('/processing'),
                        type: 'basic',
                        classes: {
                            wrapper: 'upload-button',
                            icon: 'opacity-1'
                        },
                        icon: 'heroicons_outline:plus-circle',
                        meta: {
                            category: 'showroom',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, smartVusNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/smartvus/upload');
                        }
                    },
                    {
                        id: 'failed',
                        title: translations['failed'],
                        active: pathParameters.includes('/failed'),
                        type: 'basic',
                        icon: 'heroicons_outline:x',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        meta: {
                            category: 'showroom',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, smartVusNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/smartvus/upload/failed');
                        }
                    },
                    {
                        id: "processing",
                        title: translations['processing'],
                        active: pathParameters.includes('/smartvus/upload/processing'),
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:refresh',
                        meta: {
                            category: 'configurator',
                            parentId: 'smartvus'
                        },
                        function: (item): void => {
                            this.setActiveStatus(item, smartVusNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/smartvus/upload/processing');
                        },
                    }
                ]
            },
        ];

        // Submenu for Shared Content
        const sharesNavigationData: FuseNavigationItem[] = [
            {
                id: 'all_shares',
                type: 'group',
                classes: {
                    wrapper: 'hidden'
                },
                children: [
                    {
                        id: 'share_content',
                        title: 'Share Content',
                        type: 'basic',
                        icon: 'mat_outline:open_in_new',
                        classes: {
                            wrapper: 'studio-button',
                            icon: 'opacity-1'
                        },
                        function: (item) => {
                        }
                    },
                    {
                        id: "shares",
                        title: translations['sidebar.all'],
                        active: pathParameters.includes('/shares'),
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:collection',
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, sharesNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/shares');
                            this._filterService.setSharedContentFilter('all');
                        },
                    },
                    {
                        id: "shares_deactivated",
                        title: translations["sidebar.deactivated"],
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:collection',
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, sharesNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/shares');
                            this._filterService.setSharedContentFilter('shares_deactivated');
                        },
                    },
                    {
                        id: "shares_password_protected",
                        title: translations['sidebar.password-protected'],
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:collection',
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, sharesNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/shares');
                            this._filterService.setSharedContentFilter('shares_password_protected');
                        },
                    }
                ]

            }
        ];

        // Submenu for Web Publishing
        const embedsNavigationData: FuseNavigationItem[] = [
            {
                id: 'all_embeds',
                type: 'group',
                classes: {
                    wrapper: 'hidden'
                },
                children: [
                    {
                        id: 'new_embed',
                        title: 'New Embed',
                        type: 'basic',
                        icon: 'mat_outline:open_in_new',
                        classes: {
                            wrapper: 'studio-button',
                            icon: 'opacity-1'
                        },
                        function: (item) => {
                        }
                    },
                    {
                        id: "embeds",
                        title: translations['sidebar.all'],
                        active: pathParameters.includes('/embeds'),
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:collection',
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, embedsNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/embeds');
                            this._filterService.setEmbedsContentFilter('all');
                        },
                    },
                    {
                        id: "embeds_deactivated",
                        title: translations["sidebar.deactivated"],
                        //active: pathParameters.includes('/shares'),
                        type: 'basic',
                        badge: {
                            title: '0',
                            classes: 'px-2 bg-primary text-white rounded-full'
                        },
                        icon: 'heroicons_outline:collection',
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, embedsNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/embeds');
                            this._filterService.setEmbedsContentFilter('embeds_deactivated');
                        },
                    },

                ]

            },
            {
                id: 'divider-1',
                type: 'divider'
            },
            {
                id: 'embed_locations_parent',
                type: 'group',
                classes: {
                    wrapper: 'hidden'
                },
                children: [
                    {
                        id: "embed_locations",
                        title: 'Locations',
                        //active: pathParameters.includes('/shares'),
                        type: 'basic',
                        icon: 'heroicons_outline:collection',
                        meta: {},
                        function: (item): void => {
                            this.setActiveStatus(item, embedsNavigationData, Navigations.subNavigation);
                            this.navigateToRouteInWorkspace('/embeds/locations');
                        },
                    }
                ]

            }
        ];

        switch (navigationContentId) {
            case NavigationContentIDs.smartvus:
                this._activeNavigationItem.next({name: "SmartVu's", path: 'smartvus'});
                return smartVusNavigationData;
            case NavigationContentIDs.shares:
                this._activeNavigationItem.next({name: translations['sidebar.shared-content'], path: 'shares'});
                return sharesNavigationData;
            case NavigationContentIDs.embeds:
                this._activeNavigationItem.next({name: translations['sidebar.web-publishing'], path: 'embeds'});
                return embedsNavigationData;
            default:
                return mainNavigationData;
        }
    }


    /**
     * This method navigates to a route inside a workspace.
     * @param route - the route where the app should navigate to. Should start with a '/'.
     */
    navigateToRouteInWorkspace(route: string): void {

        this._sharedService.currentWorkspaceToken$
            .pipe(take(1))
            .subscribe((workspaceToken: string) => {
                if (workspaceToken) {
                    this._router.navigate([`/workspaces/${workspaceToken}${route}`])
                }
            })
    }


    /**
     * This method handles the active state of the navigation options.
     * @param item - navigation element
     * @param navigation - all navigation elements
     * @param navigationName - the name of the navigation
     * @private
     */
    private setActiveStatus(item: FuseNavigationItem, navigation: FuseNavigationItem[], navigationName?: string): void {
        if (navigationName === Navigations.mainNavigation) {
            this._activeNavigationItem.next({name: item.title});
        }

        item.active = true;

        for (const element of navigation) {
            if (element.children) {
                for (const child of element.children) {
                    if (child.id !== item.id) {
                        child.active = false;
                    }
                }
            }
        }

        this.refreshNavigation(navigationName);
    }

    //
    // Getters and setters
    //

    set closedSidebar(value) {
        this._closedSidebar.next(value);
    }

    set closedSubMenu(value) {
        this._closedSubMenu.next(value);
    }

}

<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
        class="light bg-white print:hidden"
        [appearance]="'default'"
        [mode]="isScreenSmall ? 'over' : 'side'"
        [name]="Navigations.mainNavigation"
        [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-self-start h-16 ml-5">
            <img
                    class="w-40 cursor-pointer"
                    src="assets/images/logo/smartvu-ai-logo-500px.png"
                    alt="Logo image"
                    [routerLink]="['/workspaces']"
            >
        </div>
    </ng-container>
</fuse-vertical-navigation>


<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
            class="relative flex flex-0 items-center w-full h-16 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden gap-2" [ngClass]="{'px-4': router.url !== '/workspaces', 'px-6': router.url === '/workspaces'}">
        <!-- Navigation toggle button -->
        <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <!-- Breadcrumbs -->
        <div class="hidden sm:flex flex-wrap items-center font-medium" *ngIf="(sharedService.allSpaces$ | async)?.length > 0">
            <div>
                <a class="whitespace-nowrap text-primary-500" [routerLink]="['/workspaces']">Workspaces</a>
            </div>
            <ng-container *ngIf="router.url !== '/workspaces'">
                <ng-container *ngIf="sharedService.currentWorkspaceObject$ | async as workspace">
                    <div class="flex items-center ml-1 whitespace-nowrap">
                        <mat-icon
                                class="icon-size-5 text-secondary"
                                [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                        <a class="ml-1 text-primary-500 cursor-pointer" (click)="redirectToWorkspaceRoot()">{{workspace.title}}</a>
                    </div>

                    <div class="flex items-center ml-1 whitespace-nowrap"
                         *ngIf="navigationBuilderService.activeNavigationItem$ | async as activeNavItem">
                        <mat-icon
                                class="icon-size-5 text-secondary"
                                [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                        <a class="ml-1 text-primary-500" [routerLink]="['workspaces', workspace.token, activeNavItem.path]">{{activeNavItem.name}}</a>
                    </div>
                </ng-container >

            </ng-container>
<!--            <ng-container *transloco="let t">-->

<!--                <div class="flex items-center ml-1 whitespace-nowrap"-->
<!--                     *ngIf="router.url.includes('/shares')">-->
<!--                    <mat-icon-->
<!--                            class="icon-size-5 text-secondary"-->
<!--                            [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>-->
<!--                    <a class="ml-1 text-primary-500">{{t('sidebar.shared-content')}}</a>-->
<!--                </div>-->

<!--                <div class="flex items-center ml-1 whitespace-nowrap"-->
<!--                     *ngIf="router.url.includes('/embeds')">-->
<!--                    <mat-icon-->
<!--                            class="icon-size-5 text-secondary"-->
<!--                            [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>-->
<!--                    <a class="ml-1 text-primary-500">{{t('sidebar.web-publishing')}}</a>-->
<!--                </div>-->
<!--            </ng-container>-->

        </div>

        <img src="assets/images/logo/smartvu-ai-logo-500px.png" class="w-40" *ngIf="this.navigationBuilderService.closedSidebar$ | async">


        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <ng-container *ngIf="sharedService.allSpaces$ | async as allSpaces">
                <simple-menu [isMultiselect]="false" [menuItems]="allSpaces" class="w-56" *ngIf="allSpaces && allSpaces.length !== 0 && adminService.isAdmin$ | async"></simple-menu>
            </ng-container>


            <languages></languages>

            <span class="bg-primary text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded" *ngIf="adminService.isAdmin$ | async">Admin</span>

            <!-- Prepared and commented until needed -->
            <!-- <admin-toggle *ngIf="adminService.isAdmin$ | async"></admin-toggle>-->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">


        <mat-drawer-container class="flex-auto">

            <!-- Drawer -->
            <mat-drawer
                    id="sub-sidebar"
                    class="w-72 dark:bg-gray-900"
                    [autoFocus]="false"
                    [mode]="'side'"
                    [opened]="!(navigationBuilderService.closedSubMenu$ | async) && router.url !== '/workspaces' && !router.url.includes('/learning-centre/examples') && !router.url.includes('/learning-centre/tutorials') && !router.url.includes('/user/settings')"
                    #matDrawer>
                <!-- Sub sidebar -->
                <custom-sidebar [name]="Navigations.subNavigation" [useFuseNavigation]="true" [contentId]="setInitialSubNavigation()">
                    <div class="text-3xl font-bold tracking-tighter" style="margin-left: 16px">
                        {{(navigationBuilderService.activeNavigationItem$ | async)?.name}}
                    </div>
                </custom-sidebar>
            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col">

                <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
        Otherwise, layout changes won't be registered and the view won't be updated! -->
                <router-outlet *ngIf="true"></router-outlet>

            </mat-drawer-content>

        </mat-drawer-container>


    </div>
</div>



import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {
    catchError,
    map, merge,
    Observable, of,
    shareReplay,
    switchMap,
    tap
} from "rxjs";
import {environment} from "../../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";
import {SharedService} from "./shared.service";
import {IGroup} from "../interfaces/group.interface";
import {Router} from "@angular/router";
import {AdminService} from "../../modules/admin/admin.service";
import Logger from "./logger";
import {File} from "../models/file";
import {FileManagerService} from "../../modules/file-manager/file-manager.service";
import {SmartVu} from "../models/smartvu";
import {Workspace} from "../models/workspace";
import {UserProfile} from "../models/user-profile";

/**
 * This service handles the API Requests which are used in the application.
 *
 * The authorization headers for our Rails backend are generated automatically from the Auth0 interceptor. This is configured in the app.module.ts file.
 */
@Injectable({providedIn: 'root'})
export class ApiService {

    /**
     * This observable holds an array with all the user workspaces.
     */
    allSpaces$: Observable<Workspace[]> = this.getAllSpaces();

    uploadTypes$: Observable<any[]> = this.getUploadsTypes();


    constructor(private _http: HttpClient,
                private _authService: AuthService,
                private _sharedService: SharedService,
                private _fileManagerService: FileManagerService,
                private _router: Router,
                private _adminService: AdminService) {
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods.
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns all SmartVus from a space.
     * @param workspaceToken - the token of the workspace from which we will get the data.
     * @param disableSpacesCheck - a boolean value which indicates if the all spaces length should be checked
     */
    allSmartVus(workspaceToken: string, disableSpacesCheck?: boolean): Observable<{ smartVus: SmartVu[], allPages: number } | { smartVus: SmartVu[] } | any> {

        return this.allSpaces$
            .pipe(
                switchMap((allSpaces: Workspace[]) => {

                        if (!disableSpacesCheck) {
                            if (!workspaceToken) {
                                if (allSpaces[0]?.token) {
                                    workspaceToken = allSpaces[0].token;
                                } else {
                                    workspaceToken = 'a6f26b3b4d2230b45157975b7867b5309f0ac5c8da5fd801';
                                }

                            }

                            this._sharedService.setAllSpaces(allSpaces);

                            if (allSpaces && allSpaces.length !== 0) {
                                if (workspaceToken) {
                                    this._sharedService.setCurrentWorkspaceObject(workspaceToken);
                                }
                            } else {
                                // use default demo space
                                this._sharedService.setCurrentWorkspaceToken('a6f26b3b4d2230b45157975b7867b5309f0ac5c8da5fd801');
                            }
                        }


                        return this.getSmartVusByPage(1, workspaceToken)
                            .pipe(
                                switchMap(({smartVus, allPages}) => this.triggerPageRequests(smartVus, allPages, workspaceToken)),
                            )
                    }
                )
            )
    }

    /**
     * Get all groups existing groups from a space.
     *
     * @param workspaceToken - the token of a space.
     * @returns An array of group objects.
     */
    getGroupsOfSpace(workspaceToken?: string): Observable<IGroup[]> {
        if (workspaceToken) {
            return this.getGroupsRequest(workspaceToken);
        }

        return this._sharedService.currentWorkspaceObject$
            .pipe(
                shareReplay(),
                switchMap(
                    (workspaceObject: any) => {
                        if (workspaceObject && workspaceObject.token) {
                            return this.getGroupsRequest(workspaceObject.token);
                        }
                        return of(null);
                    }
                )
            )
    }


    /**
     * Loads a SmartVu object from the Vuframe API by its identifier
     *
     * @param identifier the unique identifier of the SmartVu
     * @returns an Observable that returns the SmartVu on success
     */
    getSmartVu(identifier: string): Observable<SmartVu> {
        return this._http.get<SmartVu>(`${environment.apiBaseUrl}/internal/v1/smartvus/${identifier}`)
            .pipe(
                catchError(err => {
                    Logger.reportError("The getSmartVu request has failed.", err)
                    return of(null);
                })
            );
    }

    /**
     * Returns the user profile.
     *
     * The backend reads the Auth0 Access Token and returns the user profile.
     */
    getUserProfile(): Observable<UserProfile> {
        return this._http.get<UserProfile>(`${environment.apiBaseUrl}/internal/v1/profiles`)
            .pipe(
                tap((user: UserProfile) => this._adminService.isAdmin = user.admin),
                catchError(err => {
                    Logger.reportError("The getUserProfile request has failed", err);
                    return of(null);
                })
            );
    }

    // TODO: refactor this api call
    setUserSalesFlags(body: any): Observable<any> {
        return this._http.put(`${environment.apiBaseUrl}/internal/v1/profiles`, body)
            .pipe(
                catchError(err => {
                    Logger.reportError("The setUserSalesFlags request has failed", err);
                    return of(null);
                })
            );
    }

    /**
     * This method send the user email to our backend. A new auth0 user is created from our backend.
     * @param email - Email of the user.
     * @param subscribe - if the user wants to be notified for updates
     */
    signUp(email: string, subscribe: boolean): Observable<any> {
        return this._http.post<any>(`${environment.apiBaseUrl}/internal/v1/profiles/signup?email=${encodeURIComponent(email)}&subscribe=${subscribe}`, {})
            .pipe(
                map(response => true),
                catchError(err => {
                    Logger.reportError("The sign up has failed.", err);
                    return of(err);
                }))
    }

    getIntercomUsersByEmail(email: string): Observable<any> {
        return this._http.get<any>(`/intercom-users?email=${email}`);
    }

    /**
     * Get files
     */
    getAllUploads(workspaceToken: string): Observable<any> {
        return this._http.get<any>(`${environment.aura3dApiUrl}/uploads?workspace_token=${workspaceToken}`)
            .pipe(
                map(data => {

                    const files = data.map(el => new File(el.storage_key, el.size, el.status, el.token, el.download_url, el.owner, el.created_at, el.trashed_at));
                    this._fileManagerService.files = files;
                    return files;
                })
            );
    }


    /**
     * This api call sends a body containing filename, path, size and extension. The backend checks if the file can be uploaded and returns a response.
     * @param body - FileInfo object containing filename, path, size and extension.
     */
    peekingServiceCheck(body: any): Observable<any> {
        return this._http.post<any>(`${environment.aura3dApiUrl}/upload/peek`, body)
    }


    getUploadByToken(uploadToken: string): Observable<any> {
        return this._http.get<any>(`${environment.aura3dApiUrl}/uploads/${uploadToken}`)
            .pipe(
                map(data => this._fileManagerService.file = new File(data.storage_key, data.size, data.status, data.token, data.download_url, data.owner, data.created_at, data.trashed_at))
            );
    }

    getUploadsTypes(): Observable<any> {
        return this._http.get<any>(`${environment.aura3dApiUrl}/upload/types`)
            .pipe(
                shareReplay()
            );
    }

    uploadFile(fileName: string, workspaceToken: string, formatIdentifier: string, size: number): Observable<any> {

        return this._http.post<any>(`${environment.aura3dApiUrl}/uploads`, {
            filename: fileName,
            workspace_token: workspaceToken,
            format_identifier: formatIdentifier,
            size: size,
            context: "stable"
        });
    }

    deleteFile(fileToken: string): Observable<any> {
        return this._http.delete<any>(`${environment.aura3dApiUrl}/uploads/${fileToken}`);
    }

    restoreFile(fileToken: string): Observable<any> {
        return this._http.post<any>(`${environment.aura3dApiUrl}/uploads/${fileToken}/restore`, {});
    }

    uploadFileToPresignedUrl(presignedUrl: string, file: any): Observable<any> {
        return this._http.put<any>(presignedUrl, file);
    }

    getUploadStatus(statusUrl: string): Observable<any> {
        return this._http.get<any>(statusUrl);
    }

    triggerUploadCallbackRequest(callBackUrl: string): Observable<any> {
        return this._http.post<any>(callBackUrl, {});
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Private methods accessible only in this service.
    // -----------------------------------------------------------------------------------------------------


    /**
     * Returns all spaces to which the user has access.
     */
    private getAllSpaces(): Observable<Workspace[]> {
        return this._http.get<Workspace[]>(`${environment.apiBaseUrl}/internal/v1/workspaces`)
            .pipe(
                shareReplay(),
                catchError(err => {
                    Logger.reportError("The getAllSpaces request has failed.", err);
                    return of(null);
                })
            );
    }

    /**
     * Gets all groups from a space.
     * @param workspaceToken - the token of a space.
     */
    private getGroupsRequest(workspaceToken: string): Observable<IGroup[]> {
        return this._http.get(`${environment.apiBaseUrl}/internal/v1/workspaces/${workspaceToken}/groups`)
            .pipe(
                map((data: any) => {
                    const groups: IGroup[] = [];
                    for (const group of data) {
                        groups.push({
                            title: group.title,
                            token: group.token,
                        });
                    }
                    return groups;
                }),
                catchError(err => {
                    Logger.reportError("The getGroups request has failed for workspace with token " + workspaceToken, err);
                    return of(null);
                })
            )
    }

    /**
     * Returns all SmartVus from a page. Paginated request.
     * @param page
     * @param workspaceToken
     */
    private getSmartVusByPage(page: number, workspaceToken?: string): Observable<{ smartVus: SmartVu[], allPages: number }> {
        let pageParam = page;
        if (!pageParam) {
            pageParam = 1;
        }
        return this._http.get(`${environment.apiBaseUrl}/internal/v1/workspaces/${workspaceToken}/smartvus`, {
            params: new HttpParams().set('page', pageParam),
            observe: 'response'
        }).pipe(
            map((response: any) => {
                const allPages = response.headers.get('X-Vf-Total-Pages')
                const smartVus = response.body;

                return {
                    smartVus: smartVus,
                    allPages: allPages
                }
            }),
            catchError(err => {
                Logger.reportError("The getSmartVusByPage request has failed for page " + pageParam, err);
                return of(null);
            })
        )
    }

    /**
     * This method triggers the requests for each SmartVu page.
     *The requests are running in parallel and when a request is ready the response has been immediately added to the NGRX Store.
     *
     * @param smartVus
     * @param allPages
     * @param workspaceToken
     * @private
     */
    private triggerPageRequests(smartVus: SmartVu[], allPages: number, workspaceToken: string): any {

        if (smartVus && smartVus.length !== 0) {
            if (allPages) {
                let requests = []

                for (let i = 1; i <= allPages; i++) {
                    if (i === 1) {
                        requests.push(of({smartVus: smartVus}));
                    } else {
                        requests.push(this.getSmartVusByPage(i, workspaceToken))
                    }

                }
                return merge(...requests);
            }
        }
    }

}

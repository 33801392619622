import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, RouterModule} from '@angular/router';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import auth0_config from '../../auth0.config.json';
import {environment} from "../environments/environment";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {SmartvusEffects} from "./store/smartvus";
import {metaReducers, reducers} from "./store";
import Bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular'
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {AddHeaderInterceptor} from "./shared/interceptors/add-header.interceptor";
import {IntercomModule} from "ng-intercom";

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always'
};

Bugsnag.start(
    {
        apiKey: environment.bugsnagApiKey,
        enabledReleaseStages: ['production'],
        appVersion: environment.version
    })

export function errorHandlerFactory() {
    return new BugsnagErrorHandler()
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        //Auth0 Module
        AuthModule.forRoot({
            domain: auth0_config.domain,
            clientId: auth0_config.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin + '/callback',
                audience: 'https://api.vuframe.com',
                responseType: 'token id_token',
                scope: 'openid email profile',
            },
            issuer: 'https://id.vuframe.com/',
            cacheLocation: 'localstorage',
            httpInterceptor: {
                allowedList:
                    [
                        `${environment.apiBaseUrl}/internal/v1/workspaces`,
                        `${environment.apiBaseUrl}/internal/v1/workspaces/*`,
                        `${environment.apiBaseUrl}/internal/v1/smartvus/*`,
                        `${environment.apiBaseUrl}/internal/v1/profiles`,
                        `${environment.apiBaseUrl}/internal/v1/*`,
                        `${environment.aura3dApiUrl}/*`,

                    ]
            }
        }),

        // Intercom
        IntercomModule.forRoot({
            appId: "g57b3hew", // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),

        // Google Analytics
        NgxGoogleAnalyticsModule.forRoot(environment.ga4TrackingCode),
        NgxGoogleAnalyticsRouterModule,

        StoreModule.forRoot(reducers, {metaReducers}),

        EffectsModule.forRoot([SmartvusEffects])
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: ErrorHandler, useFactory: errorHandlerFactory},
        {provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true}
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {File} from "../../shared/models/file";

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    // Private
    private _file: BehaviorSubject<File | null> = new BehaviorSubject(null);

    private _files: BehaviorSubject<File[]> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _http: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for files
     */
    get files$(): Observable<File[]> {
        return this._files.asObservable();
    }

    set files(value: File[]) {
        this._files.next(value);
    }

    addFile(value: File) {
        this._files.next([...this._files.getValue(), value]);
    }

    /**
     * Getter for item
     */
    get file$(): Observable<File> {
        return this._file.asObservable();
    }

    set file(value: File) {
        this._file.next(value);
    }
}

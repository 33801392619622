import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

/**
 * This service handles the logic for filtering SmartVus by groups.
 */
@Injectable({providedIn: 'root'})
export class FilterService {
    private _groupsFilter: BehaviorSubject<string> = new BehaviorSubject<string>('');
    groupsFilter$: Observable<string> = this._groupsFilter.asObservable();

    private _sharedContentFilter: BehaviorSubject<string> = new BehaviorSubject<string>('');
    sharedContentFilter$: Observable<string> = this._sharedContentFilter.asObservable();

    private _embedsContentFilter: BehaviorSubject<string> = new BehaviorSubject<string>('');
    embedsContentFilter$: Observable<string> = this._sharedContentFilter.asObservable();

    constructor() {
    }

    setGroupsFilter(groupTitle: string): void {
        this._groupsFilter.next(groupTitle);
    }

    setSharedContentFilter(filter: string) {
        this._sharedContentFilter.next(filter);
    }

    setEmbedsContentFilter(filter: string) {
        this._embedsContentFilter.next(filter);
    }
}

/**
 * This class represents the FileInfo object.
 * This object is sent to the Peeking Service
 */
export class FileInfo {
    name: string;
    path: string;
    size: number;
    peekingServiceResponse: any;
    uploadingStatus: any;
    processingEstimate: any;
    extension: string;


    constructor(name: string, size: number, path?: string) {
        this.name = name;
        this.size = size;
        this.path = path;
        this.extension = this.setExtension(name);
    }

    setExtension(name: string): string {
        const lastDot = name.lastIndexOf('.');
        return name.substring(lastDot + 1);
    }

}

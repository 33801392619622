import {Injectable} from "@angular/core";
import * as CryptoJS from 'crypto-js';
import {environment} from "../../../environments/environment";
@Injectable({providedIn: 'root'})
export class CryptoJsService{

    secretKey = environment.intercomSecretKey;
    constructor() {
    }

    createIntercomHash(email: string) {
        const parsedSecretKey = CryptoJS.enc.Utf8.parse(this.secretKey);
        const parsedEmail = CryptoJS.enc.Utf8.parse(email);

        return CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(parsedEmail, parsedSecretKey))
    }
}
